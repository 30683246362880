import "./App.css";

function App() {
  return (
    <div className="App w-full h-full">
      <div className="w-full h-full bg-red-900">
        <p className="text-white">Menu</p>
      </div>
    </div>
  );
}

export default App;
